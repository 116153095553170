#header {
    display: flex;
    /* flex: 1; */
    justify-content: space-between;
    padding: 1em 2em;
    font-size: 14px;
    padding-bottom:0;
    margin-bottom: 0em !important;
}

.header-right {
    display: flex;
    gap: 40px;
    align-items: center;
}

#header div,
#header a {
    cursor: pointer;
    color: #fafafa;
    text-decoration: none;
    text-decoration-line: none;
}

.cvtalize-header {
    display: block;
    color: white;
    margin: 0px;
    text-align: center;
    font-weight: 300;
}

.cvtalize-header--large {
    font-size: 55px;
    margin-bottom: 0px;
}


#header .header-right>div:hover {
    font-weight: 500;
}

#motto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fafafa;
}

#blog-body #motto  h3{
    color: #2e2e2e;
}

#blog-body #motto {
    margin: 0;
}

/* #res {
    display: flex;
    justify-content: center;
    transform: scale3d(.54, .54, .54);
    transform-origin:center top;
    transform-style: flat;
    overflow: hidden;
} */

#res .resume__controls {
    display: none;
}


/* #res .container:nth-child(2) {
z-index:1;
} */

/* #res .container:nth-child(1), #res .container:nth-child(3){
    transform: scale3d(.95,.95,.95);
    transform-origin:left bottom;
    z-index:0;
    position: relative;
    opacity: .95;
} */

/* #res .container:nth-child(1) {
    right: -30%;
    margin-left: -40%;
} */

/* #res .container:nth-child(3) {
    left: -30%;
    margin-right: -40%;
} */

#get-started {
    margin-top: 60vh;
    display: flex;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    overflow: hidden;
}

#get-started .get-started-button {
    display: block;
    align-self: center;
    padding: 1em 2em;
    margin: 2em;
    background-color: #2dc08d;
    border-radius: 4px;
    border: 1px solid #2dc08d;
    color: #fafafa;
    cursor: pointer;
    text-decoration: none;
}

.intro-highlight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
}

#benefits {
    display: flex;
    justify-content: space-between;
    padding: 2em 5em;
    color: #fafafa;
    gap: 3.5em;
    flex-wrap: wrap;
}

#benefits img {
    filter: invert();
    width: 3em;
}


#benefits>div {
    max-width: 15%;
    width: 15%;
}

.resume__container {
    border-radius: 4px !important;
}


/* Media Query for Mobile Devices */
/* @media (max-width: 1px) {
    body {
        background-position: center 0em;
        background-size: 100vw;
    }
} */

body {
    background-position: center 0em;
    background-size: 100vw;
}

/* Media Query for Mobile Devices */
@media (max-width: 1060px) {
    #benefits {
        padding: 1em;
        gap: 2em;
    }

    #benefits>div {
        max-width: 45%;
        width: 45%;
    }

    #get-started {
        margin-top: 45vh;
    }

    body {
        background-position: center 0em;
        background-size: 45em;
    }

    #motto {
        margin-top: 19em;
        padding: 1em;
    }

    #get-started {
        margin-top: 0;
    }

    .header-right .home {
        display: none;
    }
}

@media  (max-width: 1060px) and (min-width: 700px) {

    body {
        background-position: center 0em;
        background-size: 100vw;
    }

    #motto {
        background-color: rgba(0, 0, 0, .4);
    }
}