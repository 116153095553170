
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;600&&family=Open+Sans:wght@100;300;400;500;600&display=swap');

body {
  margin: 0;
  /* height: 100vh; */
  background: url('../public/background.jpg') no-repeat top center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
  background-color: #061210;
}

body.edit {
 /* height: 100%; */
 /* min-height: 100vh;
 min-width: 100vw; */
 background-attachment: fixed;
}

#root {
  display: flex;
  min-height: 100vh;
}

body.edit #header .logo {
  display:none; 
}


.logo {
  height: 3em;
  padding: 0.5em; 
}

@media (max-width: 480px) { 
  body.edit #header .logo, body #header .logo {
    display: block; 
  }

  body #header {
    padding: .5em 1.5em .5em .5em;
    /* justify-content: center; */
    align-items: center;
  }

  #edit { 
    width: 100%;
  } 
  /* #edit .container { overflow: inherit !important; } */

  #edit .container { 
    width: 100vw;
    max-width: initial;
  }
  #edit .resume__container {
     overflow: scroll;
     margin: -2.5em;
     max-width: initial;
  }

  #header { 
    background-color: rgba(0, 0, 0, .8);
  }
}


.bmc-container { 
  color: white;
  margin: 5px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: sticky; */
  /* margin-bottom: 20em; */
  padding: 1em 0.5em;
  box-sizing: border-box;
  margin-bottom: 85px;
  border-radius: 4px;
  /* zoom: 1.2; */
  z-index:1;
}

.bmc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: rgb(189, 95, 255);
  color: white;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 8px;
  z-index: 0;
  cursor: pointer;
  transition: all 0.25s ease 0s;
  /* zoom:.8; */
  margin-bottom: 1em;
  transition: .3s ; 
}


.bmc img {
  width: 36px;
  height: 36px;
}

#bmc-wbtn {
  display: none !important;
}


/* https://www.fourkitchens.com/blog/article/fix-scrolling-performance-css-will-change-property/ */
